import { Dispatch, SetStateAction, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment/moment";
import { axiosInstance } from "../../../../services/axios.service";
import { Charge, Page } from "../../../../types/common.types";
import { getAccessToken } from "../../../../services/auth.service";
import queryKeysConstants from "../../../../constants/queryKeys.constants";

async function getOverallCharges(
  fromDate: Date,
  toDate: Date,
  chargeType: string[],
  invoiceStatus: string | null,
  doNotDeduct: boolean | null,
  page = 1,
  size = 10,
): Promise<Page<Charge>> {
  const { data } = await axiosInstance.get(`/charges/overall`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    params: {
      from_date: moment(fromDate).format("YYYY-MM-DD"),
      to_date: moment(toDate).format("YYYY-MM-DD"),
      charge_type: chargeType.length ? chargeType.join(",") : null,
      invoice_status: invoiceStatus,
      do_not_deduct: doNotDeduct,
      page,
      size,
    },
  });
  return data;
}

interface useCarsProps {
  minPage: number;
  maxPage: number;
  isFetching: boolean;
  isLoading: boolean;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  charges: Charge[];
  totalAmount: number;
}

export function useCharges(
  size: number,
  fromDate: Date,
  toDate: Date,
  invoiceStatus: string | null,
  chargeType: string[],
  doNotDeduct: boolean | null,
): useCarsProps {
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching } = useQuery(
    [
      queryKeysConstants.charges,
      fromDate,
      toDate,
      chargeType.join(","),
      invoiceStatus,
      doNotDeduct,
      page,
      size,
    ],
    () =>
      getOverallCharges(
        fromDate,
        toDate,
        chargeType,
        invoiceStatus,
        doNotDeduct,
        page,
        size,
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );
  const maxPage = data ? Math.ceil(data.total / data.size) : 100;

  return {
    minPage: 1,
    maxPage,
    isFetching,
    isLoading,
    page,
    setPage,
    charges: data?.items || [],
    totalAmount: data?.total_amount || 0,
  };
}
