import {
  BlockStack,
  Card,
  ChoiceList,
  IndexFilters,
  IndexFiltersMode,
  IndexFiltersProps,
  IndexTable,
  Link,
  Page,
  Text,
  useSetIndexFiltersMode,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { isEmpty } from "lodash";
import { useRentsPayments } from "./hooks/useRentsPayments";
import { RentPayment } from "../../../types/common.types";
import {
  formatDate,
  formatMoney,
  dateRanges,
  getAgentNameById,
  getCarNameById,
} from "../../../helpers/helpers";
import DateRangeSelectorPopup, {
  DateRange,
} from "../../../componenets/DateRangeSelectorPopup";
import { useAgents } from "../Agents/hooks/useAgents";
import { useCars } from "../Cars/hooks/useCars";

export default function RevenuePage() {
  const { t } = useTranslation();

  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Filtering);
  const appliedFilters: IndexFiltersProps["appliedFilters"] = [];

  const [activeDateRange, setActiveDateRange] = useState<DateRange>(
    dateRanges[3],
  );
  const [selectedAgents, setSelectedAgents] = useState<string[] | undefined>(
    undefined,
  );
  const [selectedCars, setSelectedCars] = useState<string[] | undefined>(
    undefined,
  );

  const columns = [t("date"), t("car_name"), t("taken_by"), t("amount")];

  const { agents } = useAgents(10);
  const { cars } = useCars(10);
  const { minPage, maxPage, page, isFetching, setPage, payments, totalAmount } =
    useRentsPayments(
      15,
      activeDateRange.period.since,
      activeDateRange.period.until,
      selectedAgents,
      selectedCars,
    );

  const handleSelectedAgentsChange = useCallback(
    (value: string[]) => setSelectedAgents(value),
    [],
  );
  const handleSelectedAgentsRemove = useCallback(
    () => setSelectedAgents(undefined),
    [],
  );

  const handleSelectedCarsChange = useCallback(
    (value: string[]) => setSelectedCars(value),
    [],
  );
  const handleSelectedCarsRemove = useCallback(
    () => setSelectedCars(undefined),
    [],
  );

  const handleFiltersClearAll = useCallback(() => {
    handleSelectedAgentsRemove();
    handleSelectedCarsRemove();
  }, [handleSelectedAgentsRemove, handleSelectedCarsRemove]);

  const filters = [
    {
      key: "taken_by",
      label: t("taken_by"),
      filter: (
        <ChoiceList
          title={t("taken_by")}
          titleHidden
          choices={agents.map((agent) => ({
            label: agent.name,
            value: agent.id as string,
          }))}
          selected={selectedAgents || []}
          onChange={handleSelectedAgentsChange}
          allowMultiple
        />
      ),
    },
    {
      key: "cars",
      label: t("cars"),
      filter: (
        <ChoiceList
          title={t("cars")}
          titleHidden
          choices={cars.map((car) => ({
            label: `${car.name} (${car.license_plate})`,
            value: car.id as string,
          }))}
          selected={selectedCars || []}
          onChange={handleSelectedCarsChange}
          allowMultiple
        />
      ),
    },
  ];

  if (selectedAgents && !isEmpty(selectedAgents)) {
    appliedFilters.push({
      key: "taken_by",
      label: (selectedAgents as string[])
        .map((agent) => getAgentNameById(agents, agent))
        .join(", "),
      onRemove: handleSelectedAgentsRemove,
    });
  }

  if (selectedCars && !isEmpty(selectedCars)) {
    appliedFilters.push({
      key: "cars",
      label: (selectedCars as string[])
        .map((car) => getCarNameById(cars, car))
        .join(", "),
      onRemove: handleSelectedCarsRemove,
    });
  }

  const rowMarkup = payments.map((payment: RentPayment, index) => (
    <IndexTable.Row id={payment.id as string} key={payment.id} position={index}>
      <IndexTable.Cell>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link dataPrimaryLink url={`/admin/rents/${payment.rent_id}`}>
          <Text fontWeight="bold" as="span">
            {formatDate(payment.date, true)}
          </Text>
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>{getCarNameById(cars, payment.car_id)}</IndexTable.Cell>
      <IndexTable.Cell>
        {getAgentNameById(agents, payment.taken_by_agent_id)}
      </IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(payment.amount)}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page fullWidth title={t("revenue")}>
      <BlockStack gap="500">
        <DateRangeSelectorPopup
          initialSelectedRangeIndex={3}
          ranges={dateRanges}
          onApply={(activeDateRange: DateRange) => {
            setActiveDateRange(activeDateRange);
          }}
        />

        <Card padding="0">
          <IndexFilters
            hideQueryField
            mode={mode}
            setMode={setMode}
            filters={filters}
            appliedFilters={appliedFilters}
            onQueryChange={() => {}}
            onQueryClear={() => {}}
            onClearAll={handleFiltersClearAll}
            selected={0}
            tabs={[]}
          />
          <IndexTable
            resourceName={{
              singular: t("revenue"),
              plural: t("revenue"),
            }}
            headings={
              columns.map(
                (column): IndexTableHeading => ({ title: column }),
              ) as NonEmptyArray<IndexTableHeading>
            }
            itemCount={payments.length}
            loading={isFetching}
            selectable={false}
            pagination={{
              hasPrevious: page > minPage,
              hasNext: maxPage > 1 && page < maxPage,
              onPrevious: () => setPage(page - 1),
              onNext: () => setPage(page + 1),
            }}
          >
            <IndexTable.Row rowType="subheader" position={0} id="total">
              <IndexTable.Cell>{t("total")}</IndexTable.Cell>
              <IndexTable.Cell />
              <IndexTable.Cell />
              <IndexTable.Cell>{formatMoney(totalAmount)}</IndexTable.Cell>
            </IndexTable.Row>
            {rowMarkup}
          </IndexTable>
        </Card>
      </BlockStack>
    </Page>
  );
}
