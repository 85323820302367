import {
  BlockStack,
  Card,
  Text,
  IndexTable,
  InlineStack,
  Button,
  Box,
} from "@shopify/polaris";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Car, MonthlyPayment } from "../../../../../types/common.types";
import { useMonthlyPayments } from "./hooks/useMonthlyPayments";
import { formatDate, formatMoney } from "../../../../../helpers/helpers";
import AddMonthlyPaymentModal from "./AddMonthlyPaymentModal";

export default function MonthlyPayments({ car }: { car: Car }) {
  const { t } = useTranslation();
  const columns = [{ label: t("date") }, { label: t("amount") }];

  const [addMonthlyPaymentModalActive, setAddMonthlyPaymentModalActive] =
    useState(false);
  const { monthlyPayments, isFetching, totalAmount } = useMonthlyPayments(
    car.id as string,
    10,
  );

  const rowMarkup = monthlyPayments.map(
    (monthlyPayment: MonthlyPayment, index) => (
      <IndexTable.Row
        id={monthlyPayment.id as string}
        key={monthlyPayment.id}
        position={index}
      >
        <IndexTable.Cell>
          {formatDate(monthlyPayment.date, true, true)}
        </IndexTable.Cell>
        <IndexTable.Cell>{formatMoney(monthlyPayment.amount)}</IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  return (
    <Card padding="0">
      <BlockStack gap="200">
        <Box
          paddingInlineStart="300"
          paddingInlineEnd="300"
          paddingBlockStart="200"
        >
          <InlineStack align="space-between">
            <Text as="span" fontWeight="bold">
              {t("monthly_payments")}
            </Text>
            <Button
              variant="plain"
              onClick={() => setAddMonthlyPaymentModalActive(true)}
            >
              {t("add")}
            </Button>
          </InlineStack>
        </Box>

        <IndexTable
          resourceName={{
            singular: t("monthly_payment"),
            plural: t("monthly_payments"),
          }}
          itemCount={monthlyPayments.length}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          loading={isFetching}
          selectable={false}
        >
          <IndexTable.Row rowType="subheader" position={0} id="total">
            <IndexTable.Cell>{t("total")}</IndexTable.Cell>
            <IndexTable.Cell>{formatMoney(totalAmount)}</IndexTable.Cell>
          </IndexTable.Row>
          {rowMarkup}
        </IndexTable>
      </BlockStack>
      <AddMonthlyPaymentModal
        car={car as Car}
        active={addMonthlyPaymentModalActive}
        setActive={setAddMonthlyPaymentModalActive}
      />
    </Card>
  );
}
