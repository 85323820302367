import { Modal, FormLayout } from "@shopify/polaris";
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import TextFieldInput from "../../../componenets/TextFieldInput";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { Agent, Charge } from "../../../types/common.types";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import { useAddMiscellaneousCharge } from "./hooks/useAddMiscellaneousCharge";
import DatePickerInput from "../../../componenets/DatePickerInput";
import SelectInput from "../../../componenets/SelectInput";

const schema = yup
  .object({
    label: yup.string().required(),
    amount: yup.number().min(1).required(),
    paid_by: yup.string().required(),
    date: yup.date().required(),
  })
  .required();

export default function NewMiscellaneousChargeModal({
  active,
  setActive,
  agents,
}: {
  active: boolean;
  setActive: (status: boolean) => void;
  agents: Agent[];
}) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, setValue, reset } = useForm<Charge>({
    defaultValues: {
      label: "",
      amount: 0,
      paid_by: "",
      date: new Date(),
    },
    resolver: yupResolver(schema),
  });

  const addCharge = useAddMiscellaneousCharge();

  const handleClose = () => {
    reset({
      label: "",
      amount: 1,
      paid_by: agents[0].id as string,
      date: new Date(),
    });

    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (charge: Charge) => {
    setLoading(true);

    try {
      await addCharge(charge);

      await queryClient.invalidateQueries([
        queryKeysConstants.miscellaneous_charges,
      ]);

      handleClose();

      onShowToast("Charge Saved", false);
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (agents.length) setValue("paid_by", agents[0].id as string);
  });

  return (
    <Modal
      open={active}
      onClose={handleClose}
      title={t("new_charge")}
      primaryAction={{
        content: t("save"),
        disabled: loading,
        loading,
        onAction: handleSubmit(onHandleSubmit),
      }}
      secondaryActions={[
        {
          content: t("cancel"),
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextFieldInput
            control={control}
            label={t("label")}
            name="label"
            type="text"
          />
          <TextFieldInput
            control={control}
            label={t("amount")}
            name="amount"
            type="number"
          />
          <SelectInput
            control={control}
            name="paid_by"
            label={t("paid_by")}
            options={agents.map((agent) => ({
              label: agent.name,
              value: agent.id,
            }))}
          />
          <DatePickerInput control={control} name="date" label={t("date")} />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
