import {
  Card,
  IndexTable,
  Page,
  useIndexResourceState,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import React, { useContext, useState } from "react";
import { Charge } from "../../../types/common.types";
import { useMiscellaneousCharges } from "./hooks/useMiscellaneousCharges";
import {
  formatDate,
  formatMoney,
  getAgentNameById,
} from "../../../helpers/helpers";
import NewMiscellaneousChargeModal from "./NewMiscellaneousChargeModal";
import AlertContext, {
  AlertContextType,
} from "../../../contexts/alert.context";
import { queryClient } from "../../../services/queryClient.service";
import queryKeysConstants from "../../../constants/queryKeys.constants";
import { useDeleteMiscellaneousCharges } from "./hooks/useDeleteMiscellaneousCharges";
import { useAgents } from "../Agents/hooks/useAgents";

export default function MiscellaneousChargesPage() {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();

  const [chargeModalActive, setChargeModalActive] = useState(false);

  const { agents, isLoading: isAgentsLoading } = useAgents();
  const { minPage, maxPage, isFetching, page, setPage, charges } =
    useMiscellaneousCharges(10);

  const deleteCharges = useDeleteMiscellaneousCharges();

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(charges.map((charge: Charge) => ({ ...charge })));

  const columns = [
    { label: t("label") },
    { label: t("amount") },
    { label: t("paid_by") },
    { label: t("date") },
  ];

  const handleDeleteCharges = async () => {
    onShowToast("Deleting Charges", false);

    try {
      await deleteCharges(selectedResources);
      await queryClient.invalidateQueries([
        queryKeysConstants.miscellaneous_charges,
      ]);

      clearSelection();
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
    }
  };

  const rowMarkup = charges.map((charge: Charge, index) => (
    <IndexTable.Row
      id={charge.id as string}
      key={charge.id}
      position={index}
      selected={selectedResources.includes(charge.id as string)}
    >
      <IndexTable.Cell>{charge.label}</IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(charge.amount)}</IndexTable.Cell>
      <IndexTable.Cell>
        {charge.paid_by ? getAgentNameById(agents, charge.paid_by) : "-"}
      </IndexTable.Cell>
      <IndexTable.Cell>{formatDate(charge.date, true, true)}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page
      fullWidth
      title={t("miscellaneous_charges")}
      primaryAction={{
        content: t("add"),
        onAction: () => {
          setChargeModalActive(true);
        },
      }}
    >
      <Card padding="0">
        <IndexTable
          resourceName={{
            singular: t("miscellaneous_charge"),
            plural: t("miscellaneous_charges"),
          }}
          selectedItemsCount={
            allResourcesSelected ? "All" : selectedResources.length
          }
          onSelectionChange={handleSelectionChange}
          headings={
            columns.map(
              (column): IndexTableHeading => ({ title: column.label }),
            ) as NonEmptyArray<IndexTableHeading>
          }
          itemCount={charges.length}
          loading={isFetching || isAgentsLoading}
          promotedBulkActions={[
            {
              content: t("delete_charges"),
              onAction: handleDeleteCharges,
            },
          ]}
          pagination={{
            hasPrevious: page > minPage,
            hasNext: maxPage > 1 && page < maxPage,
            onPrevious: () => setPage(page - 1),
            onNext: () => setPage(page + 1),
          }}
        >
          {rowMarkup}
        </IndexTable>
      </Card>
      <NewMiscellaneousChargeModal
        active={chargeModalActive}
        setActive={setChargeModalActive}
        agents={agents}
      />
    </Page>
  );
}
