import { FormLayout, Modal } from "@shopify/polaris";
import { useForm } from "react-hook-form";
import React, { useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import TextFieldInput from "../../../../../componenets/TextFieldInput";
import { Agent, CarCharge } from "../../../../../types/common.types";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import FileUpload from "../../../../../componenets/FileUpload";
import { useAddCarCharge } from "./hooks/useAddCarCharge";
import DatePickerInput from "../../../../../componenets/DatePickerInput";
import SelectInput from "../../../../../componenets/SelectInput";
import CheckboxInput from "../../../../../componenets/CheckboxInput";

interface Props {
  carId: string;
  active: boolean;
  setActive: (status: boolean) => void;
  agents: Agent[];
}

const schema = yup
  .object({
    type: yup.string().required(),
    amount: yup.number().min(1).required(),
    paid_by: yup.string().required(),
    date: yup.date().required(),
    do_not_deduct: yup.boolean().required(),
  })
  .required();

export default function AddCarChargeModal({
  carId,
  active,
  setActive,
  agents,
}: Props) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const types = ["VIGNETTE", "ADBLUE", "TECHNICAL_VISIT", "OTHER"];

  const { control, reset, handleSubmit, setValue } = useForm<CarCharge>({
    defaultValues: {
      type: types[0],
      amount: 0,
      paid_by: "",
      date: new Date(),
      do_not_deduct: false,
    },
    resolver: yupResolver(schema),
  });
  const addCarCharge = useAddCarCharge(carId);

  const handleClose = () => {
    reset({
      type: types[0],
      amount: 0,
      paid_by: agents[0].id as string,
      date: new Date(),
      do_not_deduct: false,
    });
    setFile(null);

    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (charge: CarCharge) => {
    setLoading(true);

    try {
      await addCarCharge({ charge, invoice: file });

      await queryClient.invalidateQueries([
        queryKeysConstants.cars,
        carId,
        "charges",
      ]);

      handleClose();

      onShowToast("Car Charge Saved", false);
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
      console.error(e.response.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (agents.length) setValue("paid_by", agents[0].id as string);
  });

  return (
    <Modal
      open={active}
      title={t("add_charge")}
      onClose={handleClose}
      primaryAction={{
        content: t("save"),
        onAction: handleSubmit(onHandleSubmit),
        loading,
      }}
      secondaryActions={[
        {
          content: t("cancel"),
          onAction: handleClose,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <SelectInput
            control={control}
            name="type"
            label={t("type")}
            options={types.map((type) => ({
              label: t(type.toLowerCase()),
              value: type,
            }))}
          />
          <TextFieldInput
            control={control}
            name="amount"
            label={t("amount")}
            type="number"
          />
          <SelectInput
            control={control}
            name="paid_by"
            label={t("paid_by")}
            options={agents.map((agent) => ({
              label: agent.name,
              value: agent.id,
            }))}
          />
          <DatePickerInput control={control} name="date" label={t("date")} />
          <CheckboxInput
            control={control}
            name="do_not_deduct"
            label={t("do_not_deduct")}
          />
          <FileUpload label={t("invoice")} file={file} setFile={setFile} />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
