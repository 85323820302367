import {
  BlockStack,
  Card,
  ChoiceList,
  IndexFilters,
  IndexFiltersMode,
  IndexFiltersProps,
  IndexTable,
  Link,
  Page,
  useSetIndexFiltersMode,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import React, { useCallback, useState } from "react";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { isEmpty } from "lodash";
import { Charge, ChargeType } from "../../../types/common.types";
import {
  dateRanges,
  formatDate,
  formatMoney,
  getAgentNameById,
} from "../../../helpers/helpers";
import DateRangeSelectorPopup, {
  DateRange,
} from "../../../componenets/DateRangeSelectorPopup";
import { useCharges } from "./hooks/useCharges";
import { useAgents } from "../Agents/hooks/useAgents";

export default function ChargesPage() {
  const { t } = useTranslation();
  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Filtering);

  const columns = [
    t("label"),
    t("date"),
    t("invoice"),
    t("amount"),
    t("paid_by"),
    t("do_not_deduct"),
  ];

  const [activeDateRange, setActiveDateRange] = useState<DateRange>(
    dateRanges[3],
  );
  const [invoiceStatus, setInvoiceStatus] = useState<string[]>([]);
  const [chargeType, setChargeType] = useState<string[]>([]);
  const [doNotDeduct, setDoNotDeduct] = useState<string[]>([]);

  const handleInvoiceStatusChange = useCallback(
    (value: string[]) => setInvoiceStatus(value),
    [],
  );
  const handleInvoiceStatusRemove = useCallback(() => setInvoiceStatus([]), []);

  const handleChargeTypeChange = useCallback(
    (value: string[]) => setChargeType(value),
    [],
  );
  const handleChargeTypeRemove = useCallback(() => setChargeType([]), []);

  const handleDoNotDeductChange = useCallback(
    (value: string[]) => setDoNotDeduct(value),
    [],
  );
  const handleDoNotDeductRemove = useCallback(() => setDoNotDeduct([]), []);

  const handleFiltersClearAll = useCallback(() => {
    handleInvoiceStatusRemove();
    handleChargeTypeRemove();
  }, [
    handleInvoiceStatusRemove,
    handleChargeTypeRemove,
    handleDoNotDeductRemove,
  ]);

  function disambiguateLabel(key: string, value: any[] | string): string {
    switch (key) {
      case "invoiceStatus":
        return `${t("invoice")} ${t("is")} ${t(value).toLowerCase()}`;
      case "chargeType":
        return `${t("charge_type")}: ${value
          .map((v: string) => t(v.toLowerCase()))
          .join(", ")}`;
      case "doNotDeduct":
        return `${t("do_not_deduct")}: ${t(value)}`;
      default:
        return value as string;
    }
  }

  const { minPage, maxPage, page, isFetching, setPage, charges, totalAmount } =
    useCharges(
      15,
      activeDateRange.period.since,
      activeDateRange.period.until,
      invoiceStatus.length ? invoiceStatus[0] : null,
      chargeType,
      doNotDeduct.length ? doNotDeduct[0] === "yes" : null,
    );

  const { agents, isLoading: isAgentsLoading } = useAgents();

  const filters = [
    {
      key: "chargeType",
      label: t("charge_type"),
      filter: (
        <ChoiceList
          title={t("charge_type")}
          titleHidden
          choices={[
            { label: t("oil_change"), value: "OIL_CHANGE" },
            { label: t("miscellaneous_charge"), value: "MISCELLANEOUS_CHARGE" },
            { label: t("car_charge"), value: "CAR_CHARGE" },
          ]}
          selected={chargeType || []}
          onChange={handleChargeTypeChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "invoiceStatus",
      label: t("invoice_status"),
      filter: (
        <ChoiceList
          title={t("invoice_status")}
          titleHidden
          choices={[
            { label: t("present"), value: "present" },
            { label: t("not_present"), value: "not_present" },
          ]}
          selected={invoiceStatus || []}
          onChange={handleInvoiceStatusChange}
        />
      ),
      shortcut: true,
    },
    {
      key: "doNotDeduct",
      label: t("do_not_deduct"),
      filter: (
        <ChoiceList
          title={t("do_not_deduct")}
          titleHidden
          choices={[
            { label: t("yes"), value: "yes" },
            { label: t("no"), value: "no" },
          ]}
          selected={doNotDeduct || []}
          onChange={handleDoNotDeductChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters: IndexFiltersProps["appliedFilters"] = [];

  if (!isEmpty(invoiceStatus)) {
    const key = "invoiceStatus";

    appliedFilters.push({
      key,
      label: disambiguateLabel(key, invoiceStatus),
      onRemove: handleInvoiceStatusRemove,
    });
  }

  if (!isEmpty(chargeType)) {
    const key = "chargeType";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, chargeType),
      onRemove: handleChargeTypeRemove,
    });
  }

  if (!isEmpty(doNotDeduct)) {
    const key = "doNotDeduct";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, doNotDeduct),
      onRemove: handleDoNotDeductRemove,
    });
  }

  const rowMarkup = charges.map((charge: Charge, index) => (
    <IndexTable.Row id={charge.id as string} key={charge.id} position={index}>
      <IndexTable.Cell>
        {charge.type !== ChargeType.MISCELLANEOUS_CHARGE
          ? t(charge.label.toLowerCase())
          : charge.label}
      </IndexTable.Cell>
      <IndexTable.Cell>{formatDate(charge.date, true)}</IndexTable.Cell>
      <IndexTable.Cell>
        {charge.invoice ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link url={charge.invoice?.public_url} target="_blank">
            {t("invoice")}
          </Link>
        ) : (
          "-"
        )}
      </IndexTable.Cell>
      <IndexTable.Cell>{formatMoney(charge.amount)}</IndexTable.Cell>
      <IndexTable.Cell>
        {charge.paid_by ? getAgentNameById(agents, charge.paid_by) : "-"}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {charge.do_not_deduct ? t("yes") : t("no")}
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <Page fullWidth title={t("charges")}>
      <BlockStack gap="500">
        <DateRangeSelectorPopup
          initialSelectedRangeIndex={3}
          ranges={dateRanges}
          onApply={(activeDateRange: DateRange) => {
            setActiveDateRange(activeDateRange);
          }}
        />

        <Card padding="0">
          <IndexFilters
            onQueryChange={() => {}}
            onQueryClear={() => {}}
            tabs={[]}
            selected={0}
            onSelect={() => {}}
            filters={filters}
            appliedFilters={appliedFilters}
            onClearAll={handleFiltersClearAll}
            mode={mode}
            setMode={setMode}
            hideQueryField
          />
          <IndexTable
            resourceName={{
              singular: t("charge"),
              plural: t("charges"),
            }}
            headings={
              columns.map(
                (column): IndexTableHeading => ({ title: column }),
              ) as NonEmptyArray<IndexTableHeading>
            }
            itemCount={charges.length}
            loading={isFetching || isAgentsLoading}
            selectable={false}
            pagination={{
              hasPrevious: page > minPage,
              hasNext: maxPage > 1 && page < maxPage,
              onPrevious: () => setPage(page - 1),
              onNext: () => setPage(page + 1),
            }}
          >
            <IndexTable.Row rowType="subheader" position={0} id="total">
              <IndexTable.Cell>{t("total")}</IndexTable.Cell>
              <IndexTable.Cell />
              <IndexTable.Cell />
              <IndexTable.Cell>{formatMoney(totalAmount)}</IndexTable.Cell>
              <IndexTable.Cell />
              <IndexTable.Cell />
            </IndexTable.Row>
            {rowMarkup}
          </IndexTable>
        </Card>
      </BlockStack>
    </Page>
  );
}
