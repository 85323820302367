import { ComplexAction, FormLayout, Modal } from "@shopify/polaris";
import { useForm } from "react-hook-form";
import { useContext, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { CarDocument } from "../../../../../types/common.types";
import { queryClient } from "../../../../../services/queryClient.service";
import queryKeysConstants from "../../../../../constants/queryKeys.constants";
import AlertContext, {
  AlertContextType,
} from "../../../../../contexts/alert.context";
import FileUpload from "../../../../../componenets/FileUpload";
import { useAddCarDocument } from "./hooks/useAddCarDocument";
import SelectInput from "../../../../../componenets/SelectInput";
import DatePickerInput from "../../../../../componenets/DatePickerInput";

interface Props {
  carId: string;
  active: boolean;
  setActive: (status: boolean) => void;
}

const schema = yup
  .object({
    type: yup.string().required(),
    expiration_date: yup.date().required(),
  })
  .required();

const documentTypes = [
  "WW_CERTIFICATE",
  "GRAY_CARD",
  "REGISTRATION_RECEIPT",
  "WW_CIRCULATION_AUTHORIZATION",
  "CIRCULATION_AUTHORIZATION",
  "INSURANCE",
  "INSURANCE_CONTRACT",
  "VIGNETTE",
  "TECHNICAL_VISIT",
];

export default function AddCarDocumentModal({
  carId,
  active,
  setActive,
}: Props) {
  const { onShow: onShowToast } = useContext<AlertContextType>(AlertContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { control, reset, handleSubmit } = useForm<CarDocument>({
    defaultValues: {
      type: documentTypes[0],
      expiration_date: new Date(),
      added_at: new Date(),
    },
    resolver: yupResolver(schema),
  });
  const [file, setFile] = useState<File | null>(null);
  const addCarDocument = useAddCarDocument(carId);

  const handleClose = () => {
    reset();

    setActive(false);
    setLoading(false);
  };

  const onHandleSubmit = async (carDocument: CarDocument) => {
    if (!file && !document) {
      onShowToast("Document file is required", true);

      return;
    }

    setLoading(true);

    try {
      await addCarDocument({ carDocument, documentFile: file });

      await queryClient.invalidateQueries([
        queryKeysConstants.cars,
        carId,
        "car-documents",
      ]);

      setFile(null);
      reset({ type: documentTypes[0] });

      handleClose();

      onShowToast(t("car_document_saved"), false);
    } catch (e: any) {
      onShowToast(t("something_went_wrong"), true);
      console.error(e);
    }

    setLoading(false);
  };

  const secondaryActions: ComplexAction[] = [
    {
      content: t("cancel"),
      onAction() {
        setFile(null);
        reset({ type: documentTypes[0] });

        handleClose();
      },
    },
  ];

  return (
    <Modal
      open={active}
      title={t("add_document")}
      onClose={handleClose}
      primaryAction={{
        content: t("save"),
        onAction: handleSubmit(onHandleSubmit),
        loading,
      }}
      secondaryActions={secondaryActions}
    >
      <Modal.Section>
        <FormLayout>
          <SelectInput
            control={control}
            name="type"
            label={t("document_type")}
            options={documentTypes.map((documentType) => ({
              label: t(documentType.toLowerCase()),
              value: documentType,
            }))}
          />
          <DatePickerInput
            control={control}
            name="expiration_date"
            label={t("expiration_date")}
          />
          <FileUpload label={t("upload")} file={file} setFile={setFile} />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}
